<template>
  <div class="modal fade" :id="modalTempWa" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">Link Pembayaran</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >

        <p id="tempto" >
        Terima Kasih atas transaksi anda! <br>
        Tanggal Transaksi: {{detailPanel.createdAt | formatDate}}<br>
        Tanggal Booking: {{detailPanel.createdAt | formatDate}}<br>
        Jam Booking: {{detailPanel.jamMulai | sliceTime}} - {{detailPanel.jamSelesai | sliceTime}}<br>
        Jenis Booking: Telekonsultasi<br>
        Dokter: {{detailPanel.data.dokter.nama}}<br>
        Total yang harus dibayar : {{formatedPrice}} <br>

        Harap segera melakukan pembayaran melalui link berikut
        <a id="url" :href="linkId" @click.prevent="openLink(linkId)">Klik disini</a>
        <br>
        *jika pembayaran tidak dilakukan dalam waktu 15 menit maka konfirmasi booking harus diulang
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="copyCommand('tempto')">Salin Template</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"  @click="close()">Tutup</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    data(){
        return{

        }
    },
    props: {
        modalTempWa: { type: String, default: "modalTempWa" },
        value: Object
    },
    computed: {
        ...mapState('DayCalendarStore', {
          showModalTempWa: state => state.showModalTempWa,
          // selectedEvent: state => state.selectedEvent,
          detailPanel: state => state.detailPanel
      }),
      linkId(){
        return this.detailPanel.teleconsultPayment.linkId
      },
      // url(){
        // return this.detailPanel.teleconsultPayment
        // const baseDomain = process.env.VUE_APP_API_URL;
        // return `${baseDomain}/telemedicine/api/payments/teleconsult?linkId=${encodeURIComponent(this.linkId)}&redirect=true`
      // },
      formatedPrice(){
        return this.detailPanel.price.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR"
                    })
      }
    },
    filters: {
        formatDate: function(value) {
            return moment(value).format("dddd, DD MMMM YYYY");
        },
        formatTime: function(value) {
            return moment(value).format("HH:mm");
        },
        sliceTime: function(value) {
          return value ? value.slice(0, 5) : '-'
        }
    },
    methods:{
        ...mapMutations({
                setShowModalTempWa:'DayCalendarStore/SET_SHOW_MODAL_TEMPWA',
        }),
       close() {
            $("#" + this.modalTempWa).modal("hide");
            this.setShowModalTempWa(false);
        },
        copyCommand(element_id){
            var aux = document.createElement("div");
            aux.setAttribute("contentEditable", true);
            aux.innerHTML = document.getElementById(element_id).innerHTML;
            let link = aux.getElementsByTagName('a')[0]
            link.innerHTML = link.href
            aux.setAttribute("onfocus", "document.execCommand('selectAll',false,null)"); 
            document.body.appendChild(aux);
            aux.focus();
            document.execCommand("copy");
            document.body.removeChild(aux);
            toastr.success('Berhasil disalin')
        },
        openLink(url){
          window.open(url)
        }
    },
    mounted () {
      $("#" + this.modalTempWa).modal();
      this.wizard = new KTWizard("kt_wizard_v1", {
          startStep: 1,
      });
    },
}
</script>

<style>

</style>